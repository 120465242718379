body{
    margin: 0;
    /*min-width: 1600px;*/
    overflowY: hidden;
}
#root{
    /*min-width: 1600px;*/
    overflowY: hidden;

}

.helpClass{
    width: 600px;
    max-width: 800px;

}

.helpLayerClass{

}

.introjs-floating{


}
.introjs-tooltiptext{
    font-size: 16px;
}


